/* ==========================================================================
   Inports SASS
   ========================================================================== */
@import "_fonts";
@import "_variables";

/* ==========================================================================
   Base styles: opinionated defaults
   ========================================================================== */

* {
	box-sizing: border-box;
}

audio,
canvas,
iframe,
img,
svg,
video {
	vertical-align: middle;
}



/* ==========================================================================
     Author's custom styles
     ========================================================================== */
html {
	color: $black;
	font-size: 1em;
	line-height: 1.4;
	font-family: $Rubik;
}


body {
	display: flex;
	flex-direction: column;

	&>* {
		width: 100%;
		margin: 0;
	}
}

/*
   	* 	HEADER
   	*/

header {
	position: fixed;
	background-color: $white;
	z-index: 20;
	top: 0;

	@supports (position:sticky) {
		position: sticky;
	}

}

.header-container {
	display: flex;
	justify-content: flex-end;
	align-items: center;

	h1 {
		font-weight: $bold;
		text-transform: uppercase;
		font-size: 2em;
		margin: 0 auto 0 1em;
		padding: 0;
		visibility: hidden;

		a {
			color: $purple;
			text-decoration: none;
		}

		&.show {
			visibility: visible;
		}
	}
}

header nav.menu {
	display: flex;
	padding: 1.3em 1em;

	ul {
		display: flex;
		list-style-type: none;
		margin: 0;
		padding: 0;

		a {
			color: $black;
			display: block;
			text-decoration: none;
			font-weight: $semibold;
			font-style: italic;
			margin: 0 1em;

			&:hover {
				color: $purple;
			}
		}

		img:hover {
			animation: wiggle 0.5s;
			animation-iteration-count: 1;
		}

		@keyframes wiggle {
			0% {
				transform: rotate(0deg);
			}

			30% {
				transform: rotate(-10deg);
			}

			65% {
				transform: rotate(30deg);
			}

			100% {
				transform: rotate(0deg);
			}
		}

	}

	@media only screen and (max-width: $screen-xs-max) {
		ul {
			display: none;
		}
	}
}

header nav.hamburgerMenu {
	display: flex;
	align-items: center;
	background-color: $purple;
	display: flex;
	padding: 1.2em 2em;
	color: $white;

	h5 {
		margin: 0;
		padding: 0;
		// font-style: italic;
		font-weight: $light;
		text-transform: uppercase;
		font-size: 1.4em;
		transform: skewX(-15deg);
		transition: transform 300ms;
	}

	.hamburger {
		margin-right: 0.8em;
		width: 35px;

		span {
			display: block;
			height: 3px;
			width: 30px;
			background-color: $white;
			border-radius: 2px;
			margin-top: 5px;
			transition: margin-left 300ms;

			&:nth-child(1) {
				margin-top: 0;
				margin-left: 6px;
			}

			&:nth-child(2) {
				margin-left: 3px;
			}
		}
	}

	&:hover {
		cursor: pointer;

		span {
			&:nth-child(1) {
				margin-left: 0px;
			}

			&:nth-child(2) {
				margin-left: 0px;
			}
		}

		h5 {
			transform: skewX(0deg);
		}
	}
}

main {
	display: flex;
	flex-direction: column;

	&>* {
		display: flex;
		flex-direction: row;
		justify-content: center;
		min-height: 40vh;

		&>.inner-container {
			width: 100%;
			max-width: $screen-md-max;
		}
	}
}

/*
   	* 	Lading page
   	*/

.landing.container {
	background-color: $green;
	color: $white;
	min-height: 85vh;

	padding-top: 3em; //if browser wont support sticky

	.inner-container {
		display: flex;

		&>* {
			width: 50%;
		}

		&>.content {
			padding: 0 4em 2em 1em;
		}
	}

	.content {
		display: flex;
		flex-direction: column;
	}

	h1 {
		font-weight: $bold;
		text-transform: uppercase;
		font-size: 3em;

		&.fixed {
			position: fixed;
			transition: all 1s, margin-top 0s;
			left: 0;
			top: 0;
			z-index: 22;
			margin-top: 0;
		}

		&.animate {
			left: 1em !important;
			top: 13px !important;
			font-size: 2em;
			padding: 0;
			color: $purple;
		}
	}

	h2 {
		color: $purple;
		font-weight: $semibold;
		font-style: italic;
		font-size: 2.5em;
		margin-top: 0.5em;
		margin-bottom: 0.5;
		line-height: normal;
	}

	p {
		font-size: 1.3em;
	}

	.list-container {
		align-self: center;
		max-width: 20em;
		margin-top: 2em;


		h3 {
			color: $purple;
			font-weight: $bold;
			margin-top: 1em;
			font-size: 1.5em;
		}

		ul {
			list-style: none;
			padding: 0;

			li {
				margin-bottom: 1em;

				&::before {
					content: '';
					display: inline-block;
					height: 0.9em;
					width: 1em;
					background-image: url('/img/list-style.svg');
					background-size: contain;
					background-repeat: no-repeat;
					padding-left: 1em;
					display: inline-block;
					vertical-align: middle;
					/* This line */
				}

				&:hover {
					cursor: pointer;
					color: $purple;
				}
			}
		}
	}


	.figure-container {
		position: relative;
	}

	.figure-container figure {
		position: absolute;
		width: 50vw;
		overflow: hidden;
		margin: 0;
		height: 100%;
		display: flex;

		img.header-image {
			object-fit: cover;
			width: 100%;
		}

		img.overlay {
			position: absolute;
			width: 120%;
			transform: translateX(-10%);
			bottom: 0;
		}
	}


	@media only screen and (max-width: $screen-xs-max) {
		.inner-container {
			flex-direction: column;

			&>* {
				width: 100%;
			}


			.figure-container {
				max-height: 40vh;
				overflow: hidden;

				figure {
					position: static;
					width: 100%;
				}
			}
		}
	}

	@supports (position:sticky) {
		padding-top: 0;
	}
}


/*
   	* 	ONTMOETEN
   	*/

.ontmoeten.container {
	background-color: $purple;
	color: $white;
	min-height: 85vh;
	padding-top: 3em;

	.inner-container {
		display: flex;
		padding: 0 1em 4em 1em;
		flex-direction: column;
		align-items: center;
	}

	h2 {
		font-weight: $semibold;
		font-size: 2.5em;
		margin-top: 0.5em;
		margin-bottom: 1.5em;
		line-height: normal;
		max-width: 10em;
		align-self: flex-start;
	}

	.persons-container {
		width: 90%;
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
		grid-gap: 2.5em;


		.persons {
			min-height: 20vh;
			display: flex;
			flex-direction: column;

			h3 {
				font-size: 2em;
				margin: 0;
				font-weight: $bold;
			}

			h4 {
				font-size: 1.3em;
				font-weight: $regular;
				font-style: italic;
				margin: 0 0 1em;
			}

			p {
				letter-spacing: 0;
				line-height: 1.875em;
				margin-bottom: 2em;
			}



			img {
				width: 100%;
			}

			hr {
				width: 90%;
				border-top: 1px solid $white;
				margin-right: auto;
				margin-left: 0;
			}

			ul {
				list-style: none;
				padding: 0;

				li {
					margin-bottom: 1em;
					font-weight: $medium;
					font-style: italic;
					font-size: 1em;

					&::before {
						content: '';
						display: inline-block;
						height: 0.9em;
						width: 1em;
						background-image: url('/img/list-style_pink.svg');
						background-size: contain;
						background-repeat: no-repeat;
						padding-left: 0.5em;
						display: inline-block;
						vertical-align: middle;
					}

					&:hover {
						cursor: pointer;
						color: $black;
					}
				}
			}
		}
	}
}

.location.container {
	display: flex;
	background-color: $blue;
	color: $white;
	padding: 3em 1em 4em 1em;

	.inner-container {
		display: flex;
		flex-direction: column;
		width: 40%;
	}

	.photo {
		display: flex;
		justify-content: center;
		;
		height: 100%;
		overflow: hidden;
		position: relative;

		img {
			width: 100%;
		}

		.overlay {
			position: absolute;
			bottom: 1em;
		}
	}

	.content {
		padding: 1em 2em;

		h2 {
			font-weight: $semibold;
			font-size: 2.5em;
			margin-top: 0.5em;
			margin-bottom: 1.5em;
			line-height: normal;
			max-width: 10em;
			align-self: flex-start;
			color: $purple;
			margin-left: -10%;
			z-index: 10;
		}

		p {
			letter-spacing: 0;
			line-height: 1.875em;
			margin-bottom: 2em;
		}

		button {
			border: none;
			background: #FFFFFF;
			border-radius: 3em;
			font-weight: $medium;
			font-style: italic;
			font-size: 1.2em;
			padding: 0.5em 0.9em;
			color: #67257B;
			letter-spacing: 0;

			img {
				transform: rotate(90deg);
				margin-left: 2em;
			}


		}

		.action {
			margin-top: 1.5em;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		span {
			margin-left: 2em;
			font-weight: $medium;
			font-style: italic;
			font-size: 1.2em;

			img {
				margin-right: 1em;
			}
		}
	}

	@media only screen and (max-width: $screen-md-max) {
		.photo {
			display: none;
		}

		.content {
			width: 100%;
			max-width: 40em;

			h2 {
				margin-left: -3%;
			}
		}
	}
}

.stories.container {
	background-color: $orange;
	padding: 3em 1em 4em 1em;

	h2 {
		font-weight: $semibold;
		font-size: 2.5em;
		line-height: normal;
		max-width: 10em;
		align-self: flex-start;
		color: $white;
		margin: 0;

		&:nth-of-type(2) {
			font-weight: $regular;
			font-style: italic;
		}
	}

	.inner-container {
		display: flex;
		flex-direction: column;
		align-items: center;

		&>section {
			width: 80%;
		}
	}


	.verhalen-grid {
		width: 90%;
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
		grid-gap: 2.5em;
		height: auto;
		margin-top: 2em;

		.news-item-big {
			grid-column: 1 / -1;
			display: flex;
			flex-direction: row;
			border-radius: 5px;
			overflow: hidden;

			h4 {
				font-weight: $regular;
				font-style: italic;
				font-size: 1em;
				margin-bottom: 0.2em;
			}

			h3 {
				margin-top: 0;
				font-weight: $semibold;
				color: $purple;
				font-size: 2.5em;
			}

			p {
				max-width: 20em;
				letter-spacing: 0;
				line-height: 1.875em;
			}

			section {
				flex-grow: 1;
				background-color: $white;
				padding: 3em;
			}

			img {
				max-width: 50%;
			}

      h5 {
      	margin-top: 4em;
      	font-weight: $regular;

      	img {
      		height: 3em;
      		margin-right: 1em;
      	}
      }
		}

		.news-item {
			min-height: 20vh;
			display: flex;
			flex-direction: column;
      color: $white;

      &>* {
        width: 95%;
      }

			h3 {
        font-weight: $semibold;
        font-size: 1.2em;
        margin: 0.2em 0;
        color: $purple;
			}

			h4 {
				font-weight: $regular;
				font-style: italic;
				font-size: 1em;
				margin-bottom: 0.2em;
			}

			p {
				letter-spacing: 0;
				line-height: 1.375em;
				margin-bottom: 2em;
			}

      h5 {
        margin-top: auto;
        font-weight: $regular;

        img {
          height: 3em;
          margin-right: 1em;
        }
      }

			&>img {
				width: 100%;
			}
		}




		@media only screen and (max-width: $screen-md-max) {

			.news-item-big {
				flex-direction: column;

				img {
					max-width: 100%;
					max-height: 30vh;
					object-fit: cover;
				}
			}
		}

	}
}

.faq.container {
	background-color: $green;

  padding: 3em 1em 4em 1em;

  h2 {
  	font-weight: $semibold;
  	font-size: 2em;
  	line-height: normal;
  	align-self: flex-start;
  	color: $purple;
  	margin: 0;
  }

  .faq {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(13em, 1fr));
    grid-gap: 1.5em;
    margin-top: 2em;

    ul {
      list-style: none;
      color: $white;
      font-style: italic;
      font-weight: $light;
      line-height: 1.9em;

      li {
        margin-left: 23px;

        &:not(:nth-child(1)):hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }

      li:nth-child(1) {
        font-weight: $bold;
        font-style: normal;
        margin-bottom: 1em;
        font-size: 1.5em;
        margin-left: 0;

        &::before {
          display: inline-block;
          content: ' ';
          background-image: url('../img/list-style-faq.svg');
          background-size: 18px 18px;
          height: 18px;
          width: 18px;
          margin-right: 5px;
        }
      }
    }
  }
}

footer {
  &>section {
    display: flex;
    justify-content: center;

    ul {
      max-width: $screen-md-max;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      li {
        margin-right: 2em;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    };
  }

  &>section:nth-child(1) {
    background-color: $purple;
    color: $white;
    font-weight: $bold;
    font-size: 1.3em;
    padding: 0.5em 0;
  }

  &>section:nth-child(2) {
      font-weight: $semibold;
      font-style: italic;
      font-size: 1em;
      padding: 0.1em 0;

      ul li:last-of-type {
        margin-left: auto;
        font-weight: $regular;
        margin-right: 0;

      }
  }
}


/* ==========================================================================
     Helper classes
     ========================================================================== */

/*
   * Hide visually and from screen readers
   */

.hidden,
[hidden] {
	display: none !important;
}

/*
   * Hide visually and from screen readers, but maintain layout
   */

.invisible {
	visibility: hidden;
}
