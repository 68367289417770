@font-face {
    font-family: 'Rubik';
    src: url('/fonts/Rubik-ExtraBold.eot');
    src: local('Rubik ExtraBold'), local('Rubik-ExtraBold'),
        url('/fonts/Rubik-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Rubik-ExtraBold.woff2') format('woff2'),
        url('/fonts/Rubik-ExtraBold.woff') format('woff'),
        url('/fonts/Rubik-ExtraBold.ttf') format('truetype'),
        url('/fonts/Rubik-ExtraBold.svg#Rubik-ExtraBold') format('svg');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('/fonts/Rubik-Italic.eot');
    src: local('Rubik Italic'), local('Rubik-Italic'),
        url('/fonts/Rubik-Italic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Rubik-Italic.woff2') format('woff2'),
        url('/fonts/Rubik-Italic.woff') format('woff'),
        url('/fonts/Rubik-Italic.ttf') format('truetype'),
        url('/fonts/Rubik-Italic.svg#Rubik-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('/fonts/Rubik-LightItalic.eot');
    src: local('Rubik Light Italic'), local('Rubik-LightItalic'),
        url('/fonts/Rubik-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Rubik-LightItalic.woff2') format('woff2'),
        url('/fonts/Rubik-LightItalic.woff') format('woff'),
        url('/fonts/Rubik-LightItalic.ttf') format('truetype'),
        url('/fonts/Rubik-LightItalic.svg#Rubik-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('/fonts/Rubik-SemiBoldItalic.eot');
    src: local('Rubik SemiBold Italic'), local('Rubik-SemiBoldItalic'),
        url('/fonts/Rubik-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Rubik-SemiBoldItalic.woff2') format('woff2'),
        url('/fonts/Rubik-SemiBoldItalic.woff') format('woff'),
        url('/fonts/Rubik-SemiBoldItalic.ttf') format('truetype'),
        url('/fonts/Rubik-SemiBoldItalic.svg#Rubik-SemiBoldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('/fonts/Rubik-BoldItalic.eot');
    src: local('Rubik Bold Italic'), local('Rubik-BoldItalic'),
        url('/fonts/Rubik-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Rubik-BoldItalic.woff2') format('woff2'),
        url('/fonts/Rubik-BoldItalic.woff') format('woff'),
        url('/fonts/Rubik-BoldItalic.ttf') format('truetype'),
        url('/fonts/Rubik-BoldItalic.svg#Rubik-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('/fonts/Rubik-MediumItalic.eot');
    src: local('Rubik Medium Italic'), local('Rubik-MediumItalic'),
        url('/fonts/Rubik-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Rubik-MediumItalic.woff2') format('woff2'),
        url('/fonts/Rubik-MediumItalic.woff') format('woff'),
        url('/fonts/Rubik-MediumItalic.ttf') format('truetype'),
        url('/fonts/Rubik-MediumItalic.svg#Rubik-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('/fonts/Rubik-Black.eot');
    src: local('Rubik Black'), local('Rubik-Black'),
        url('/fonts/Rubik-Black.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Rubik-Black.woff2') format('woff2'),
        url('/fonts/Rubik-Black.woff') format('woff'),
        url('/fonts/Rubik-Black.ttf') format('truetype'),
        url('/fonts/Rubik-Black.svg#Rubik-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('/fonts/Rubik-BlackItalic.eot');
    src: local('Rubik Black Italic'), local('Rubik-BlackItalic'),
        url('/fonts/Rubik-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Rubik-BlackItalic.woff2') format('woff2'),
        url('/fonts/Rubik-BlackItalic.woff') format('woff'),
        url('/fonts/Rubik-BlackItalic.ttf') format('truetype'),
        url('/fonts/Rubik-BlackItalic.svg#Rubik-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('/fonts/Rubik-ExtraBoldItalic.eot');
    src: local('Rubik ExtraBold Italic'), local('Rubik-ExtraBoldItalic'),
        url('/fonts/Rubik-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Rubik-ExtraBoldItalic.woff2') format('woff2'),
        url('/fonts/Rubik-ExtraBoldItalic.woff') format('woff'),
        url('/fonts/Rubik-ExtraBoldItalic.ttf') format('truetype'),
        url('/fonts/Rubik-ExtraBoldItalic.svg#Rubik-ExtraBoldItalic') format('svg');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('/fonts/Rubik-Bold.eot');
    src: local('Rubik Bold'), local('Rubik-Bold'),
        url('/fonts/Rubik-Bold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Rubik-Bold.woff2') format('woff2'),
        url('/fonts/Rubik-Bold.woff') format('woff'),
        url('/fonts/Rubik-Bold.ttf') format('truetype'),
        url('/fonts/Rubik-Bold.svg#Rubik-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('/fonts/Rubik-Regular.eot');
    src: local('Rubik Regular'), local('Rubik-Regular'),
        url('/fonts/Rubik-Regular.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Rubik-Regular.woff2') format('woff2'),
        url('/fonts/Rubik-Regular.woff') format('woff'),
        url('/fonts/Rubik-Regular.ttf') format('truetype'),
        url('/fonts/Rubik-Regular.svg#Rubik-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('/fonts/Rubik-Medium.eot');
    src: local('Rubik Medium'), local('Rubik-Medium'),
        url('/fonts/Rubik-Medium.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Rubik-Medium.woff2') format('woff2'),
        url('/fonts/Rubik-Medium.woff') format('woff'),
        url('/fonts/Rubik-Medium.ttf') format('truetype'),
        url('/fonts/Rubik-Medium.svg#Rubik-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('/fonts/Rubik-Light.eot');
    src: local('Rubik Light'), local('Rubik-Light'),
        url('/fonts/Rubik-Light.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Rubik-Light.woff2') format('woff2'),
        url('/fonts/Rubik-Light.woff') format('woff'),
        url('/fonts/Rubik-Light.ttf') format('truetype'),
        url('/fonts/Rubik-Light.svg#Rubik-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('/fonts/Rubik-SemiBold.eot');
    src: local('Rubik SemiBold'), local('Rubik-SemiBold'),
        url('/fonts/Rubik-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Rubik-SemiBold.woff2') format('woff2'),
        url('/fonts/Rubik-SemiBold.woff') format('woff'),
        url('/fonts/Rubik-SemiBold.ttf') format('truetype'),
        url('/fonts/Rubik-SemiBold.svg#Rubik-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

$Rubik: "Rubik", "opensans", sans-serif;

$thin: 100;
$exlight: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$exbold: 800;
$fontstyle-black: 900;